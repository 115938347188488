



















































































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { AppEntityModel, AppQueryModel } from '@common-src/entity-model/app-entity';
import AppService from '@common-src/service3/app';
import ImportAppDialog from './import-app-dialog.vue';
import DeployAppDialog from './deploy-app-dialog.vue';
import DeployLogDialog from './deploy-log-list.vue';
import { DeploymentStatus } from '@common-src/model/enum';

const appService = new AppService();
let Timer = null;

@Component({
    components: {
        'import-app-dialog': ImportAppDialog,
        'deploy-app-dialog': DeployAppDialog,
        'deploy-log-dialog': DeployLogDialog
    }
})
export default class AppListComponent extends TableDialogFormComponent<AppEntityModel, AppQueryModel> {
    AppEntityModel = AppEntityModel;
    DeploymentStatus = DeploymentStatus;
    selectedRowKeys = [];
    created() {
        this.initTable({
            service: appService,
            queryModel: new AppQueryModel(),
            tableColumns: AppEntityModel.getTableColumns()
        });
        this.getList();
    }

    auditPassClick(model: AppEntityModel) {
        appService.auditPass(model.id).then(() => {
            this.showMessageSuccess('审核通过');
            this.getList();
        });
    }

    auditRejectClick(model: AppEntityModel) {
        appService.auditReject(model.id).then(() => {
            this.showMessageSuccess('审核未通过');
            this.getList();
        });
    }

    onSelectChange(selectedRowKeys) {
        this.selectedRowKeys = selectedRowKeys;
    }

    // 导入数据
    importAppClick() {
        (this.$refs.importAppDialog as ImportAppDialog).dialogOpen();
    }
    // 导出数据
    exportAppClick() {
        if (this.selectedRowKeys.length < 1) {
            this.showMessageWarning('请先选择要导出的应用');
            return;
        }
        let ids = this.selectedRowKeys.reduce((arr, item) => {
            const tr = item.match(/(\S*)\|/)[1];
            arr.push(tr);
            return arr;
        }, []);
        return appService.exportAppList(ids).then((res) => {
            this.showMessageSuccess('成功');
        });
    }
    // 部署弹框
    deployWork(record: AppEntityModel) {
        const data = [{
            targetVersion: record.latestVersion,
            appId: record.id
        }];
        this.batchDeployAppClick(data);
    }

    // 操作日志
    checkLogList(record: AppEntityModel) {
        (this.$refs.deployLogDialog as DeployLogDialog).logDialogOpen(record);
    }

    // 下线
    offlineClick(record: AppEntityModel) {
        this.batchUninstallClick([record.id]);
    }

    batchUninstallClick(ids = []) {
        const selectedRowIds = this.getSelectedRowIds();
        const idList = !_.isEmpty(ids) ? ids : selectedRowIds;
        if (!_.isEmpty(idList)) {
            this.startFullScreenLoading();
            appService.uninstallApp(idList).then(res => {
                if (!res) {
                    this.showMessageInfo(`下线成功`);
                    this.getList();
                    this.stopFullScreenLoading();
                } else {
                    if (Timer) {
                        clearInterval(Timer);
                    }
                    Timer = setInterval(() => {
                        this.getDeployResult(res, false);
                    }, 2000);
                }
            }).catch(err => {
                this.stopFullScreenLoading();
            });
        } else {
            this.showMessageWarning(`请选择要下线的应用！`);
        }
    }

    getDeployResult(id, isDeploy, isBatch = false) {
        appService.getDeployResult(id).then(res => {
            if (!res || res.code === -1) {
                this.showMessageInfo(`${isDeploy ? '部署' : '下线'}成功`);
                clearInterval(Timer);
                this.stopFullScreenLoading();
                Timer = null;
                this.getList();
            } else if (res && res.code !== 50015) { // 50015 还在操作中 需要继续请求
                clearInterval(Timer);
                this.stopFullScreenLoading();
                Timer = null;
                (this.$refs.deployAppDialog as DeployAppDialog).deployAppDialogOpen(res.result, isBatch);
            }
        }).catch(err => {
            // 50015
            console.log(err);
        });
    }

    async batchDeleteAppClick() {
        if (!_.isEmpty(this.selectedRowKeys)) {
            this.startFullScreenLoading();
            const selectedRowIds = this.getSelectedRowIds();
            for (let i = 0; i < selectedRowIds.length; i++) {
                await appService.delete(selectedRowIds[i]);
                this.showMessageInfo(`已删除第${i + 1}个`);
            }
            this.stopFullScreenLoading();
            this.getList();
        } else {
            this.showMessageWarning(`请选择要删除的应用！`);
        }
    }

    async batchPassAppClick() {
        if (!_.isEmpty(this.selectedRowKeys)) {
            this.startFullScreenLoading();
            this.getBatchDeployResult();
            const selectedRowIds = this.getSelectedRowIds();
            for (let i = 0; i < selectedRowIds.length; i++) {
                await appService.auditPass(selectedRowIds[i]);
                this.showMessageInfo(`已审核第${i + 1}个`);
            }
            this.stopFullScreenLoading();
            // this.getList();
        }
    }

    getBatchDeployResult() {

    }

    async batchDeployAppClick(data = []) {
        const selectedRowIds = this.getSelectedRowIds();
        const list = _.filter(this.listData, item => selectedRowIds.indexOf(item.id) > -1);
        const selectData = _.map(list, item => {
            return {
                targetVersion: item.latestVersion,
                appId: item.id
            };
        });
        const idList = !_.isEmpty(data) ? data : selectData;
        const isBatch = _.isEmpty(data) ? true : false;

        if (!_.isEmpty(idList)) {
            this.startFullScreenLoading();
            appService.deployApplication(idList).then((res) => {
                if (res) {
                    if (Timer) {
                        clearInterval(Timer);
                    }
                    Timer = setInterval(() => {
                        this.getDeployResult(res, true, isBatch);
                    }, 2000);
                } else {
                    this.showMessageInfo(`部署成功`);
                    this.stopFullScreenLoading();
                    this.getList();
                }
            }).catch(err => {
                this.stopFullScreenLoading();
            });
        }
        // if (_.isEmpty(this.selectedRowKeys)) {
        //     this.showMessageWarning('请先选择要部署的应用');
        //     return;
        // }
        // await this.batchPassAppClick();
        // const record = _.get(this.listData, '[0]');
        // if (record) {
        //     (this.$refs.batchDeployAppDialog as DeployAppDialog).dialogOpen(record);
        // }
    }

    async batchDeploy(envData) {
        if (!_.isEmpty(this.selectedRowKeys)) {
            const record = _.get(this.listData, '[0]');
            this.startFullScreenLoading('正在批量部署，请耐心等待......');
            const selectedRowIds = this.getSelectedRowIds();
            for (let i = 0; i < selectedRowIds.length; i++) {
                if (selectedRowIds[i] !== record.id) {
                    const params = {
                        id: selectedRowIds[i],
                        envConfig: envData.envConfig
                    };
                    await appService.deployApplication(params);
                    this.showMessageInfo(`已部署第${i + 2}个`);
                }
            }
            this.stopFullScreenLoading();
            this.getList();
        }
    }
}

