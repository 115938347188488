var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("应用管理")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-card",
            { staticClass: "table-query-card", attrs: { title: "查询" } },
            [
              _c("jtl-table-query-component", {
                ref: _vm.JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME,
                on: { search: _vm.searchClick },
              }),
            ],
            1
          ),
          _c(
            "a-card",
            { attrs: { title: "列表" } },
            [
              _c(
                "div",
                {
                  staticClass: "table-header-button",
                  attrs: { slot: "extra" },
                  slot: "extra",
                },
                [
                  _c(
                    "jtl-button",
                    {
                      attrs: { "table-header-child": "", type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.addClick(new _vm.AppEntityModel())
                        },
                      },
                    },
                    [_vm._v("新增")]
                  ),
                  _c(
                    "jtl-button",
                    {
                      attrs: { "table-header-child": "", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.batchUninstallClick()
                        },
                      },
                    },
                    [_vm._v("批量下线")]
                  ),
                  _vm._v("  "),
                  _c(
                    "jtl-button",
                    {
                      attrs: { "table-header-child": "", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.batchDeployAppClick()
                        },
                      },
                    },
                    [_vm._v("批量部署")]
                  ),
                  _vm._v("  "),
                  _c(
                    "jtl-button",
                    {
                      attrs: {
                        "table-header-child": "",
                        type: "primary",
                        "click-prop": _vm.exportAppClick,
                      },
                    },
                    [_vm._v("导出")]
                  ),
                  _vm._v("  "),
                  _c(
                    "jtl-button",
                    {
                      attrs: {
                        "table-header-child": "",
                        type: "primary",
                        "click-prop": _vm.importAppClick,
                      },
                    },
                    [_vm._v("导入")]
                  ),
                  _vm._v("  "),
                ],
                1
              ),
              _c("a-table", {
                attrs: {
                  loading: _vm.listLoading,
                  columns: _vm.tableColumns,
                  "data-source": _vm.listData,
                  pagination: _vm.Pagination,
                  scroll: _vm.tableScroll,
                  "row-key": _vm.TableRowKey,
                  size: _vm.TableSize,
                  "row-selection": {
                    selectedRowKeys: _vm.selectedRowKeys,
                    onChange: _vm.onSelectChange,
                  },
                },
                on: { change: _vm.tableChange },
                scopedSlots: _vm._u([
                  {
                    key: "action",
                    fn: function (text, record) {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "jtl-edit-link",
                            on: {
                              click: function ($event) {
                                return _vm.editClick(record, true)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a",
                          {
                            staticClass: "jtl-edit-link",
                            on: {
                              click: function ($event) {
                                return _vm.checkLogList(record)
                              },
                            },
                          },
                          [_vm._v("日志")]
                        ),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        record.type === "APP"
                          ? [
                              record.status ===
                              _vm.DeploymentStatus.DEPLOY_SUCCESS
                                ? [
                                    _c(
                                      "a-popconfirm",
                                      {
                                        attrs: {
                                          title: "确认下线?",
                                          "ok-text": "确认",
                                          "cancel-text": "取消",
                                        },
                                        on: {
                                          confirm: function ($event) {
                                            return _vm.offlineClick(record)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          { staticClass: "jtl-del-link" },
                                          [_vm._v("下线")]
                                        ),
                                      ]
                                    ),
                                  ]
                                : [
                                    _c(
                                      "a-button",
                                      {
                                        staticStyle: { padding: "0" },
                                        attrs: { type: "link", disabled: true },
                                      },
                                      [_vm._v("下线")]
                                    ),
                                  ],
                              _c("a-divider", { attrs: { type: "vertical" } }),
                            ]
                          : _vm._e(),
                        !record.latestVersion ||
                        (record.status ===
                          _vm.DeploymentStatus.DEPLOY_SUCCESS &&
                          record.latestVersion &&
                          record.latestVersion === record.version)
                          ? [
                              _c(
                                "a-button",
                                {
                                  staticStyle: { padding: "0" },
                                  attrs: { type: "link", disabled: true },
                                },
                                [_vm._v("部署")]
                              ),
                            ]
                          : [
                              _c(
                                "a-popconfirm",
                                {
                                  attrs: {
                                    title: "确认部署?",
                                    "ok-text": "确认",
                                    "cancel-text": "取消",
                                  },
                                  on: {
                                    confirm: function ($event) {
                                      return _vm.deployWork(record)
                                    },
                                  },
                                },
                                [
                                  _c("a", { staticClass: "jtl-del-link" }, [
                                    _vm._v("部署"),
                                  ]),
                                ]
                              ),
                            ],
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.formDialogOK },
      }),
      _c("import-app-dialog", {
        ref: "importAppDialog",
        on: { dialogOK: _vm.getList },
      }),
      _c("deploy-app-dialog", {
        ref: "deployAppDialog",
        on: { dialogOK: _vm.getList },
      }),
      _c("deploy-app-dialog", {
        ref: "batchDeployAppDialog",
        on: { dialogOK: _vm.batchDeploy },
      }),
      _c("deploy-log-dialog", { ref: "deployLogDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }