var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component form-edit-component-large",
      attrs: { title: _vm.title, visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose },
    },
    [
      _c(
        "div",
        [
          _c(
            "a-table",
            {
              staticClass: "parking-table",
              attrs: {
                "data-source": _vm.failResultList,
                pagination: false,
                bordered: false,
                size: "small",
              },
            },
            [
              _c("a-table-column", {
                key: "appIdentifier",
                attrs: {
                  title: "应用标识符",
                  "data-index": "appIdentifier",
                  width: 150,
                },
              }),
              _c("a-table-column", {
                key: "appName",
                attrs: {
                  title: "应用名称",
                  "data-index": "appName",
                  width: 150,
                },
              }),
              _c("a-table-column", {
                key: "succeed",
                attrs: { title: "部署状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (text, record) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(record.succeed ? "成功" : "失败") +
                              " " +
                              _vm._s(record.errMessage)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            {
              on: {
                click: function ($event) {
                  return _vm.refreshList()
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "jtl-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.refreshList()
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }