



































import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ICRUDQ } from '@common-src/model/interface';
import { ViewModeType } from '@common-src/model/enum';
import AppService from '@common-src/service3/app';
import { FormControlType, FormControlTableModel } from '@common-src/model/form-control';
import { AppEntityModel } from '@common-src/entity-model/app-entity';
const appService = new AppService();
let Timer = null;

@Component
export default class DeployAppDialog extends FormDialogComponent<any> {
    baseInfo = {
        name: '',
        version: '',
        id: ''
    };
    failResultList = [];
    title = '';

    refreshList() {
        this.dialogClose();
        this.$emit('dialogOK');
    }
    async deployAppDialogOpen(list, isBatch = false) {
        this.dialogVisible = true;
        this.failResultList = list;
        if (isBatch) {
            this.title = '批量部署失败提示';
        } else {
            this.title = '部署失败提示';
        }
        // this.viewMode = ViewModeType.UPDATE;
        // const result = await appService.retrieveDetail(record.id);
        // const res = await appService.systemResourceList();
        // const value = _.map(result.envConfig?.standard || [], (item) => {
        //     item.selectOptions = _.filter(res, i => item.resourceType === i.identifier);
        //     if (item.selectOptions.length === 1) {
        //         item.value = item.selectOptions[0].value;
        //     }
        //     return item;
        // });
        // const nonValue = result.envConfig?.nonStandard || [];
        // const schaFormList = [];
        // if (value && value.length >= 1) {
        //     schaFormList.push({
        //         key: 'standard',
        //         label: '标准资源',
        //         type: FormControlType.TABLE,
        //         required: true,
        //         cellType: [],
        //         columns: [
        //             {
        //                 title: '字段名称',
        //                 dataIndex: 'name'
        //             },
        //             {
        //                 title: '值',
        //                 dataIndex: 'value',
        //                 scopedSlots: { customRender: 'singleSelect' }
        //             },
        //             {
        //                 title: '说明',
        //                 dataIndex: 'remark'
        //             }
        //         ],
        //         value: value ? value : []
        //     });
        // }
        // if (nonValue && nonValue.length >= 1) {
        //     schaFormList.push({
        //         key: 'nonStandard',
        //         label: '自定义资源',
        //         type: FormControlType.FORM_EDIT_TABLE,
        //         required: true,
        //         columns: [
        //             {
        //                 title: '字段名称',
        //                 dataIndex: 'name'
        //             },
        //             {
        //                 title: '标识符',
        //                 dataIndex: 'identifier'
        //             },
        //             {
        //                 title: '值',
        //                 dataIndex: 'value',
        //                 width: 100,
        //                 scopedSlots: { customRender: 'input' }
        //             },
        //             {
        //                 title: '说明',
        //                 dataIndex: 'remark'
        //             }
        //         ],
        //         value: nonValue
        //     });
        // }
        // this.$nextTick(() => {
        //     if (this.jtlForm) {
        //         this.baseInfo = record;
        //         // this.jtlForm.initForm(record, this.viewMode);
        //         this.jtlForm.initFormByControls([ ...schaFormList ] as any);
        //     }
        // });
        // this.baseInfo = record;
        // this.dialogOK();
    }

    // dialogOK(): any {
    //     // if (!this.jtlForm) {
    //     //     return;
    //     // }
    //     this.startFullScreenLoading('正在部署，请耐心等待......');
    //     const data = [{
    //         targetVersion: this.baseInfo.latestVersion,
    //         appId: this.baseInfo.id
    //     }];
    //     return appService.deployApplication(data).then((res) => {
    //         // this.showMessageSuccess('部署应用成功');
    //         // this.dialogClose();
    //         this.stopFullScreenLoading();

    //         if (res) {
    //             if (Timer) {
    //                 clearInterval(Timer);
    //             }
    //             Timer = setInterval(() => {
    //                 this.getDeployResult(res);
    //             }, 2000);
    //         }

    //         this.$emit('dialogOK', data);
    //     })
    //         .catch(() => {
    //             // this.deployFail = true;
    //             // this.title = '批量部署失败提示';
    //             this.stopFullScreenLoading();
    //         })
    //         .finally(() => {
    //             // this.stopFullScreenLoading();
    //         });
    // }

    // getDeployResult(id) {
    //     appService.getDeployResult(id).then(res => {
    //         if (!res) {
    //             clearInterval(Timer);
    //             Timer = null;
    //         } else {
    //             this.dialogVisible = true;
    //             this.failResultList = res;
    //         }
    //     }).catch(err => {
    //         // 50015
    //         console.log(111111, err);
    //         // this.dialogVisible = true;
    //         // this.failResultList = err;
    //         // this.title = '批量部署失败提示';
    //     });
    // }
}
