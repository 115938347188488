

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ICRUDQ } from '@common-src/model/interface';
import { ViewModeType } from '@common-src/model/enum';
import AppService from '@common-src/service3/app';
import { FormControlType } from '@common-src/model/form-control';
const appService = new AppService();
@Component
export default class ImportAppDialog extends FormDialogComponent<any> {
    dialogOpen(): void {
        this.dialogVisible = true;
        this.viewMode = ViewModeType.NEW;
        this.dialogTitle = '导入应用配置';
        this.$nextTick(() => {
            if (this.jtlForm) {
                // this.jtlForm.initForm(importModel, viewMode);
                this.jtlForm.initFormByControls([{
                    key: 'file',
                    label: '上传文件',
                    type: FormControlType.UPLOAD,
                    accept: '.json',
                    maxFileNumber: 1,
                    notUploadOss: true,
                    required: true
                }] as any);
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }
        this.startFullScreenLoading('正在导入，请耐心等待......');
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                appService.importAppList(_.get(this.jtlForm, 'formModel.file')).then(res => {
                    this.showMessageSuccess('批量应用成功');
                    this.dialogClose();
                    this.$emit('dialogOK');
                    resolve(null);
                }).catch(err => {
                    reject();
                    throw err;
                });
            }).catch(err => {
                reject();
                throw err;
            });
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}
