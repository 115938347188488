








































import { Component, Mixins } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import TableComponent from '@common-src/mixins/table-component';
import { ICRUDQ } from '@common-src/model/interface';
import { ViewModeType } from '@common-src/model/enum';
import AppService from '@common-src/service3/app';
import { FormControlType, FormControlTableModel } from '@common-src/model/form-control';
import { AppEntityModel } from '@common-src/entity-model/app-entity';
const appService = new AppService();

export default class DeployLogDialog extends Mixins(TableComponent, FormDialogComponent) {
    logList = [];

    async logDialogOpen(record) {
        this.dialogVisible = true;
        const columns = [
            {
                title: '操作类型',
                dataIndex: 'deployed',
                scopedSlots: { customRender: 'deployed' }
            },
            {
                title: '版本号',
                dataIndex: 'version'
            }, {
                title: '操作结果',
                dataIndex: 'succeed',
                scopedSlots: { customRender: 'succeed' }
            }, {
                title: '操作人',
                dataIndex: 'createUserName'
            }, {
                title: '操作时间',
                dataIndex: 'createTime'
            }
        ];
        this.initTable({ listFunc: appService.getLogList, queryModel: { params: { appId: record.id } }, tableColumns: columns });
        this.getList();
        // appService.getLogList({ appId: record.id }).then(res => {
        //     this.dialogVisible = true;
        //     this.logList = res;
        // });
    }
}
